<template>
  <!-- <v-container class="fill-height app-content"> -->
  <!-- <v-content class="centrado" v-if="$store.state.datos_iniciales"> -->
  <!-- <v-main class="centrado"> -->
     <v-container fluid>
      <router-view
        :cfg="get_cfg">
      </router-view>
      <div style="position:fixed; bottom:10px; left:10px">
        <h1 v-if="!$store.state.G.PRODUCTION" style="color:red">ESTA VERSIÓN ES SÓLO PARA PRUEBAS</h1>
        ABAKAN sistemas
      </div>
     </v-container>
  <!-- </v-main> -->
</template>

<script>
import entorno from "@/common/entorno.json";

export default {
  computed: {
    get_cfg() {
      if (!this.$route.name) return null;
      if (this.$route.name === "") return null;
      if (!entorno[this.$route.name]) return null;
      return entorno[this.$route.name];
    }
  },


  methods: {

  }

};
</script>
